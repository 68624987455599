<template>
  <v-img
    :aspect-ratio="5 / 1"
    :src="
      company.cover && company.cover.route
        ? company.cover.route
        : '/static/images/bannermodulos.jpg'
    "
    class="unset-overflow profile-cover"
  >
    <v-row align="center" class="mx-0">
      <v-col class="pl-4 user-avatar-col" cols="6">
        <v-avatar :class="avatarClasses" :size="size" :tile="tile" :color="color">
          <v-img
            id="image-avatar"
            v-if="company.logo && company.logo.route"
            :src="company.logo.route"
          />
          <v-icon :size="Number(size)" :color="iconColor" v-else>{{ icon }}</v-icon>
        </v-avatar>
      </v-col>
    </v-row>
    <!-- Other Features on Cover pic -->
    <v-row class="mx-0 with-radius">
      <!-- [Aligned-Start Item] Toolbar -->
      <!-- <v-col cols="12" class="align-self-start" v-if="edit">
        <v-toolbar dense flat class="transparent" dark>
          <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">{{
            company.name
          }}</v-toolbar-title>
          <v-spacer />

          <v-btn icon>
            <v-icon>camera_alt</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col> -->

      <!-- [Aligned-End Item] Add Button -->
      <v-col align="end" cols="12" class="align-self-end" v-if="edit">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('edit')" v-bind="attrs" v-on="on" fab small class="mx-3">
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ editText ? editText : '' }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-img>
</template>
<script>
  export default {
    props: {
      company: Object,
      edit: {
        type: Boolean,
        required: false,
      },
      editText: {
        type: String,
        required: false,
      },
      size: {
        type: [String, Number],
        default: 130,
      },
      tile: {
        type: Boolean,
        default: false,
      },
      ring: {
        type: [String, Number],
        default: null,
      },
      glowClass: {
        type: String,
        default: 'neu-glow-inset',
      },
      icon: {
        type: String,
        default: 'mdi-home',
      },
      color: {
        type: String,
        default: null,
      },
      iconColor: {
        type: String,
        default: null,
      },
    },
    computed: {
      avatarClasses() {
        return {
          'align-self-center': true,
          'neu-glow-inset': true,
          'my-avatar': true,
        }
      },
    },
  }
</script>
<style lang="scss">
  .profile-cover {
    .logo {
      border-radius: 0% !important;
    }
    .my-avatar {
      border-radius: 0%;
      #image-avatar .v-image__image {
        background-size: contain;
      }
    }
    .user-avatar-col {
      position: inherit;
    }
    .v-responsive__content {
      display: flex;
    }
  }
</style>
