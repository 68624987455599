<template>
  <div class="vuse-user-profile identity">
    <v-container fluid>
      <v-row no-gutters justify="space-between" align="center">
        <smart-breadcrumbs :items="navigationElements" />
        <help class="help--breadcrumb" :helpId="7" />
      </v-row>
      <v-card color="neu-glow-inset with-radius">
        <cover
          @edit="handleEdit"
          :company="identity"
          :edit="true"
          :editText="'Editar Identidad Corporativa'"
        />

        <v-row class="mx-0" style="min-height: 50px">
          <v-col class="align-self-center my-2 py-0" align="start">
            <h1 class="font-weight-bold text-h4 basil--text">{{ identity.name }}</h1>
          </v-col>
          <v-col class="pa-0 d-flex align-flex-end">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="primary"
              right
              class="vuse-neu-ele"
            >
              <v-tabs-slider color="primary" />
              <v-tab v-for="(item, index) in items" :key="index">
                {{ item.nameEs }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card>
      <v-sheet class="transparent profile-tab-item">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in items" :key="index" dark>
            <v-sheet class="mt-3 transparent">
              <keep-alive>
                <component :is="item.code" :identity="identity" />
              </keep-alive>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </v-container>
    <v-dialog v-model="dialog" max-width="90%"
      ><v-card style="padding: 10px 1rem; margin: 0px">
        <h2 class="text-center">Editar Información</h2>

        <v-form ref="formIdentity" v-model="valid" style="width: 100%; margin: 0px">
          <v-row class="mx-0 mt-4">
            <v-col cols="12"
              ><v-text-field
                counter
                :rules="[rules.required]"
                label="Nombre de la red"
                v-model="identity.name"
            /></v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 12px">
                  <h4 class="my-2">Descripción</h4>
                  <vue-editor
                    id="editorDescription"
                    :placeholder="'Escriba la descripción de la red de empresas aquí'"
                    :editorToolbar="editorToolbar"
                    v-model="identity.description"
                  />
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 12px">
                  <h4 class="my-2">Visión</h4>
                  <vue-editor
                    id="editorView"
                    :placeholder="'Escriba la visión de la red de empresas aquí'"
                    :editorToolbar="editorToolbar"
                    v-model="identity.view"
                  />
                </v-col>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 12px">
                  <h4 class="my-2">Misión</h4>
                  <vue-editor
                    id="editorMission"
                    :placeholder="'Escriba la misión de la red de empresas aquí'"
                    :editorToolbar="editorToolbar"
                    v-model="identity.mission"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12" md="6">
            <v-col cols="12" md="6" style="padding-top: 0px; padding-bottom: 12px">
              <h4 class="my-2">Reseña histórica</h4>
              <vue-editor
                id="editorReview"
                :placeholder="'Escriba la reseña histórica de la red de empresas aquí'"
                :editorToolbar="editorToolbar"
                v-model="identity.review"
              />
            </v-col>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-row no-gutters justify="center" align="center" class="mb-5">
              <v-img
                v-if="identity.logo && identity.logo.route"
                :src="identity.logo.route"
                contain
                alt="logo"
                class="unset-overflow profile-cover"
                style="max-height: 100px; max-width: 200px"
              />
            </v-row>
            <v-row no-gutters justify="center" align="center">
              <v-file-input
                v-model="logo"
                chips
                :rules="[rules.fileSize]"
                ref="file"
                show-size
                persistent-hint
                :hint="'Ancho máximo recomendado: 200px'"
                accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                :label="'Seleccione el logo'"
                style="width: 60%"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-spacer vertical />
            <v-row no-gutters justify="center" align="center" class="mb-5">
              <v-img
                v-if="identity.cover && identity.cover.route"
                :aspect-ratio="5 / 1"
                contain
                :src="identity.cover.route"
                :alt="identity.name"
                class="unset-overflow profile-cover"
                style="max-height: 200px"
              />
            </v-row>
            <v-spacer vertical />
            <v-row no-gutters justify="center" align="center">
              <v-file-input
                v-model="cover"
                chips
                :rules="[rules.fileSize]"
                ref="cover"
                show-size
                persistent-hint
                :hint="'Dimensiones recomendadas: 1500x300px'"
                accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                :label="'Seleccione una imagen de portada'"
                style="width: 60%"
              />
            </v-row>
          </v-col>

          <v-col cols="12" style="height: 10%">
            <div class="row">
              <v-btn
                style="margin: auto; margin-top: 10px"
                :disabled="!valid"
                dark
                color="primary"
                @click="flow === 'edit' ? saveInfo() : createInfo()"
              >
                Guardar
              </v-btn>
              <v-btn
                style="margin: auto; margin-top: 10px"
                color="primary"
                dark
                @click="dialog = false"
              >
                Cancelar
              </v-btn>
            </div>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import Cover from '@/components/CorporateStructure/Cover'
  import Information from '@/components/CorporateStructure/Information'
  import Phrases from '@/components/CorporateStructure/Phrases'
  import { VueEditor } from 'vue2-editor'

  export default {
    components: {
      Cover,
      Information,
      Phrases,
      VueEditor,
    },
    data() {
      return {
        config: {},
        valid: true,
        dialog: false,
        loading: false,
        identity: {},
        tab: 0,
        description: '',
        review: '',
        view: '',
        mission: '',
        cover: null,
        logo: null,
        fileType: '',
        editorToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ['link', 'video'],
          ['clean'], // remove formatting button
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        items: [
          { code: 'information', nameEs: 'Información', nameEn: 'About' },
          { code: 'phrases', nameEs: 'Frases', nameEn: 'Phrases' },
        ],
        navigationElements: [
          {
            text: 'Estructura Corporativa',
            disabled: false,
            query: null,
            params: null,
            name: 'business/corporate-structure/AdminPanel',
            href: '/business/corporate-structure/admin-panel',
          },
          {
            text: 'Identidad Corporativa',
            disabled: true,
            href: '',
          },
        ],
        flow: 'create',
      }
    },
    created() {
      this.getIdentity()
    },
    methods: {
      getIdentity() {
        this.loading = true
        this.$axios
          .get('identities?limit=1&order=created_at&by=desc')
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.flow = 'edit'
              this.identity = response.data[0]
            } else {
              this.flow = 'create'
              this.identity = {}
            }
            this.loading = false
          })
      },

      createInfo() {
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.cover) {
          formData.append('files[0][file]', this.cover)
          formData.append('files[0][fileType]', 'cover')
          formData.append('files[0][fileResize][width]', 1500)
          formData.append('files[0][fileResize][height]', 300)
          if (this.identity.cover && this.identity.cover.id) {
            formData.append('files[0][fileId]', this.identity.cover.id)
          }
        }
        if (this.logo) {
          formData.append('files[1][file]', this.logo)
          formData.append('files[1][fileType]', 'logo')
          if (this.identity.logo && this.identity.logo.id) {
            formData.append('files[1][fileId]', this.identity.logo.id)
          }
        }
        if (this.identity.name) {
          formData.append('name', this.identity.name)
        }
        if (this.identity.description) {
          formData.append('description', this.identity.description)
        }
        if (this.identity.review) {
          formData.append('review', this.identity.review)
        }
        if (this.identity.view) {
          formData.append('view', this.identity.view)
        }
        if (this.identity.mission) {
          formData.append('mission', this.identity.mission)
        }
        this.$axios.post('identities', formData).then((response) => {
          this.identity = response.data.identity
          this.dialog = false
        })
      },
      saveInfo() {
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.identity.name) {
          formData.append('name', this.identity.name)
        }
        if (this.cover) {
          formData.append('files[0][file]', this.cover)
          formData.append('files[0][fileType]', 'cover')
          formData.append('files[0][fileResize][width]', 1500)
          formData.append('files[0][fileResize][height]', 300)
          if (this.identity.cover && this.identity.cover.id) {
            formData.append('files[0][fileId]', this.identity.cover.id)
          }
        }
        if (this.logo) {
          formData.append('files[1][file]', this.logo)
          formData.append('files[1][fileType]', 'logo')
          if (this.identity.logo && this.identity.logo.id) {
            formData.append('files[1][fileId]', this.identity.logo.id)
          }
        }
        if (this.identity.description) {
          formData.append('description', this.identity.description)
        }
        if (this.identity.review) {
          formData.append('review', this.identity.review)
        }
        if (this.identity.view) {
          formData.append('view', this.identity.view)
        }
        if (this.identity.mission) {
          formData.append('mission', this.identity.mission)
        }
        formData.append('_method', 'patch')
        this.$axios.post('identities/' + this.identity.id, formData).then((response) => {
          this.identity = response.data.identity
          this.dialog = false
        })
      },
      validate() {
        this.$refs.formIdentity.validate()
      },

      reset() {
        this.$refs.formIdentity.reset()
      },
      handleEdit() {
        this.dialog = true
        this.logo = null
        this.cover = null
      },
    },
  }
</script>
<style lang="scss">
  .identity {
    .ql-image {
      display: none;
    }
    .v-tabs > .v-tabs-bar {
      height: 100%;
    }
  }
</style>
