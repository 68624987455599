<template>
  <v-sheet class="neu-glow-inset mb-2" :height="review ? '100%' : ''">
    <v-card-text>
      <p class="text-h6 text--primary my-text" v-html="title" />
      <div
        class="px-1 my-text"
        :class="{ review: review, description: description }"
        v-html="text"
      />
    </v-card-text>
  </v-sheet>
</template>

<script>
  export default {
    name: 'SimpleText',
    props: {
      title: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      helpId: {
        type: Number,
        required: false,
      },
      review: {
        type: Boolean,
        required: false,
      },
      description: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {}
    },
    components: {},
    beforeDestroy() {
      // this.overview = null
      // delete this.overview
    },
  }
</script>
<style lang="scss">
  .my-text blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: '\201C''\201D''\2018''\2019';
  }
  .my-text blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  .description {
    max-height: 200px;
    overflow-y: auto;
  }
  .review {
    height: calc(100% - 50px);
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 0 !important;
  }
</style>
