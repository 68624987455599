<template>
  <v-row>
    <v-col class="first-column" cols="12" md="6">
      <simple-text
        :description="true"
        :title="'Descripción'"
        :text="identity.description ? identity.description : ''"
      />
      <simple-text
        class=""
        :title="'Visión'"
        :text="identity.view ? identity.view : ''"
      />
      <simple-text
        class=""
        :title="'Misión'"
        :text="identity.mission ? identity.mission : ''"
      />
    </v-col>
    <v-col cols="12" md="6">
      <simple-text
        :review="true"
        :title="'Reseña'"
        :text="identity.review ? identity.review : ''"
      />
    </v-col>
  </v-row>
</template>
<script>
  import SimpleText from '@/components/CorporateStructure/SimpleText'

  export default {
    props: {
      identity: {
        type: Object,
        required: true,
      },
    },
    components: {
      SimpleText,
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>
<style lang="scss" scoped>
  .first-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>
