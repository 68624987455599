<template>
  <div class="vuse-content-wrapper">
    <!-- Content -->
    <v-card elevation="4">
      <div class="filters">
        <v-row no-gutters justify="space-between" align="center">
          <help class="" :helpId="8" style="vertical-align: baseline" />
          <h2 class="text-center"> Frases </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small
                v-bind="attrs"
                v-on="on"
                dark
                color="primary"
                @click.prevent="handleCreate()"
                >Crear
              </v-btn>
            </template>
            <span>Crear Frase</span>
          </v-tooltip>
        </v-row>

        <label>Filtrar por:</label>

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getPhrases()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getPhrases()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Frase</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Frase</h2>

        <div class="row">
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row style="margin: 0px"
              ><v-col cols="12" md="8" style="padding-top: 0px; padding-bottom: 12px">
                <v-textarea counter v-model="text" label="Texto" /></v-col
              ><v-col cols="12" md="4">
                <v-text-field :label="'Fecha'" v-model="date" type="date" />
              </v-col>
            </v-row>

            <v-row style="margin: 0px">
              <v-col cols="12"><h4 class="text-center">Datos del autor</h4></v-col>
              <v-row class="w-100 col-12"
                ><v-col cols="12" lg="4" md="6"
                  ><v-checkbox
                    v-model="externalAuthor"
                    @change="
                      externalAuthor ? (internalAuthor = false) : (internalAuthor = true)
                    "
                    :label="'Persona externa'"
                /></v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <v-text-field
                    :disabled="internalAuthor"
                    required
                    :rules="[(v) => !!v || 'Requerido']"
                    label="Autor"
                    v-model="author" /></v-col
              ></v-row>
              <v-row class="w-100 col-12"
                ><v-col cols="12" lg="4" md="6"
                  ><v-checkbox
                    v-model="internalAuthor"
                    @change="
                      internalAuthor ? (externalAuthor = false) : (externalAuthor = true)
                    "
                    :label="'Usuario del portal'"
                /></v-col>
                <v-col
                  cols="12"
                  lg="8"
                  md="6"
                  style="padding-top: 0px; padding-bottom: 12px"
                >
                  <v-row style="margin: 0px">
                    <label style="margin: 0px auto; font-size: 13px; width: 40%"
                      >Usuario Cloud
                    </label>
                    <multiselect
                      :disabled="!internalAuthor"
                      style="width: 60%"
                      v-model="selectedUser"
                      id="ajax"
                      :custom-label="fullName"
                      track-by="id"
                      placeholder="Buscar"
                      open-direction="bottom"
                      :options="usersSearched"
                      :multiple="false"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                    >
                      <template slot="tag" slot-scope="{ option, remove }"
                        ><span class="custom__tag"
                          ><span>{{ option.name }}</span
                          ><span class="custom__remove" @click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      >
                      <template slot="clear" slot-scope="">
                        <div
                          class="multiselect__clear"
                          v-if="selectedUser && selectedUser.length"
                        /> </template
                      ><span slot="noResult"
                        >Oops! No elements found. Consider changing the search
                        query.</span
                      >
                    </multiselect>
                  </v-row>
                </v-col></v-row
              >
            </v-row>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn small
              style="margin: auto; margin-top: 10px" color="primary" @click="reset">
            Limpiar
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'

  export default {
    name: 'Phrases',
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        phrases: [],
        loading: true,
        isLoading: false,
        headers: [
          {
            text: '#',
            value: 'id',
            /* align: ' d-none', */
          },
          { text: 'Texto', value: 'text' },
          { text: 'Autor', value: 'author' },
          { text: 'Fecha de publicación', value: 'date' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        usersSearched: [{ id: 0, name: 'No tiene usuario asignado' }],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        internalAuthor: false,
        externalAuthor: true,
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Autor', order: 'author', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        users: [{ id: null, name: 'Todos' }],
        selectedUser: {},
        author: '',
        date: new Date().toISOString().split('T')[0],
        text: '',
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) || 'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.getPhrases()
    },
    computed: {},
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getPhrases()
      },
      limitText(count) {
        return `and ${count} other phrases`
      },
      asyncFind(query) {
        if (query !== '') {
          this.isLoading = true
          this.usersSearched = [{ id: 0, name: 'No tiene usuario asignado' }]
          this.$axios.get('users?search=' + query).then((response) => {
            this.usersSearched = [{ id: 0, name: 'No tiene usuario asignado' }]
            this.usersSearched = this.usersSearched.concat(response.data.data)
            this.isLoading = false
          })
        }
      },
      getUser(userId) {
        if (userId && userId !== '') {
          this.usersSearched = [{ id: 0, name: 'No tiene usuario asignado' }]
          if (userId !== 0) {
            this.$axios.get('users/' + userId).then((response) => {
              this.selectedUser = response.data
            })
          } else {
            this.selectedUser = { id: 0, name: 'No tiene usuario asignado' }
          }
        }
      },

      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },

      async getPhrases() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get('phrases?page=' + this.page + queryParams + orderString)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.text = ''
        this.author = ''
        this.selectedUser = ''
        this.date = new Date().toISOString().split('T')[0]

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.text = this.item.text
        this.author = this.item.author
        this.date = this.item.date

        if (this.item.user_id && this.item.user_id === 0) {
          this.internalAuthor = false
          this.externalAuthor = true
          this.selectedUser = { id: 0, name: 'No tiene usuario asignado' }
        } else {
          this.internalAuthor = true
          this.externalAuthor = false
          this.getUser(this.item.user_id)
        }

        this.selectedUser = this.item.user_id
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        let payload = {}
        if (this.internalAuthor && this.selectedUser && this.selectedUser.id) {
          payload = {
            text: this.text,
            date: this.date,
            user_id: this.selectedUser.id,
          }
        } else if (this.externalAuthor) {
          payload = {
            text: this.text,
            author: this.author,
            date: this.date,
          }
        }
        this.$axios.post('phrases', payload).then(() => {
          this.page = 1
          this.getPhrases()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        let payload = {}
        if (this.internalAuthor && this.selectedUser && this.selectedUser.id) {
          payload = {
            text: this.text,
            date: this.date,
            user_id: this.selectedUser.id,
          }
        } else if (this.externalAuthor) {
          payload = {
            text: this.text,
            author: this.author,
            date: this.date,
          }
        }
        this.$axios.put('phrases/' + this.item.id, payload).then(() => {
          this.page = 1
          this.getPhrases()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        this.$axios.delete('phrases/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getPhrases()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getPhrases()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-textarea textarea {
    height: 80px;
  }
</style>
